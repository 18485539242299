

.phone-number{
font-size: 14px;
color: greenyellow;
}
.blended-color {
    background: linear-gradient(to right,greenyellow ,blue, white);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

.shop-heading{
    font-size: 14px;
    font-weight: bold;
}
@media screen and (max-width: 768px) {
    .shop-heading {
      font-size: 10px;
    }

  }
  @media screen and (max-width: 280px) {
    .shop-heading {
        font-size: 6px;
      }
      .phone-number{
        font-size: 6px;
      }
    }    

.h-wrapper{
    color: white;
    z-index: 99;
}
.h-container{
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--secondary);
}
a {
    color: white;
    text-decoration: none !important; 
  }
  
.h-menu{
    gap: 2rem;

}
.h-menu>*:hover{
    cursor: pointer;
}
.menu-icon{
    display: none;

}

@media (max-width:768px){
    .menu-icon{
        display: block;
    }

    .h-menu{

        position: absolute;
        color: var(--black);
        top: 3rem;
        right: 4rem;
        flex-direction: column;
        background: white;
        font-weight: 500;
        gap: 2rem;
        padding: 3rem;
        border-radius: 10px;
        align-items: flex-start;
        box-shadow: 0px 4px 4px rgba(0,0,0,0.05);
        transition: all 300ms ease-in;
        z-index: 99;
    }

}